    import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import classNames from 'classnames';
    import PropTypes from 'prop-types';
    import { useState } from 'react';
    import Skeleton from 'react-loading-skeleton';

    /**
     * Input atom creates an input/textarea with a loading state
     * @param {{
     *      isLoading: boolean;
     *      inputRef: React.RefObject<HTMLInputElement | HTMLTextAreaElement> | undefined;
     *      color?: 'gray' | 'white';
     *      type: 'text' | 'number' | 'email' | 'password' | 'date' | 'month' | 'textarea' | 'datetime-local'; 
     *      complete: 'nickname' | 'username' | 'current-password' | 'new-password' | 'off';
     *      focus: boolean;
     *      placeholder: string | undefined;
     *      defaultValue: string | undefined;
     *      value: string | undefined;
     *      onType: ((value: string) => void) | undefined;
     *      focus: boolean | undefined;
     * }} props
     */
    function Input({ isLoading, inputRef, color, type, complete, focus, placeholder, defaultValue, value, onType, readOnly }) {
        const [reveal, setReveal] = useState(false);

        if (isLoading) {
            return (
                <Skeleton
                    baseColor="#e4e4e7"
                    highlightColor="#f4f4f5"
                    height="100%"
                    borderRadius={6}
                    containerClassName="w-full flex h-10"
                />
            );
        }

        return (
            <div
                className={classNames(
                    'w-full flex rounded-md items-center overflow-hidden shadow-md',
                    color === 'gray' ? 'bg-smgray shadow-inner' : 'bg-white shadow-md',
                )}
            >
                {type === 'textarea' ? (
                    <textarea
                        ref={inputRef}
                        autoFocus={focus}
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        value={value}
                        onChange={onType ? (e) => onType(e.target.value) : undefined}
                        readOnly={readOnly}
                        className="w-full min-h-[200px] p-5 bg-transparent outline-none"
                    />
                ) : (
                    <input
                        ref={inputRef}
                        type={type === 'password' && reveal ? 'text' : type}
                        autoComplete={complete}
                        autoFocus={focus}
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        value={value}
                        onChange={onType ? (e) => onType(e.target.value) : undefined}
                        readOnly={readOnly}
                        className="w-full px-5 h-10 bg-transparent text-sm outline-none"
                    />
                )}

                {type === 'password' && (
                    <button
                        type="button"
                        onClick={() => setReveal(!reveal)}
                        className="w-14 flex-shrink-0 flex justify-center items-center text-smgray-dark outline-none"
                    >
                        <FontAwesomeIcon icon={reveal ? faEye : faEyeSlash} />
                    </button>
                )}
            </div>
        );
    }

    Input.propTypes = {
        isLoading: PropTypes.bool,
        inputRef: PropTypes.shape(),
        color: PropTypes.oneOf(['gray', 'white']).isRequired,
        type: PropTypes.oneOf(['text', 'number', 'email', 'password', 'date', 'month', 'textarea', 'datetime-local']).isRequired,
        complete: PropTypes.oneOf(['nickname', 'username', 'current-password', 'new-password', 'off']),
        focus: PropTypes.bool,
        placeholder: PropTypes.string,
        defaultValue: PropTypes.string,
        value: PropTypes.string,
        readOnly: PropTypes.bool,
        onType: PropTypes.func,
    };

    Input.defaultProps = {
        isLoading: false,
        inputRef: undefined,
        complete: 'off',
        focus: false,
        placeholder: undefined,
        defaultValue: undefined,
        value: undefined,
        readOnly: false,
        onType: undefined,
    };

    export default Input;
