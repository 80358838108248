/* eslint-disable no-nested-ternary */
import { faCalendar, faCalendarXmark, faClock, faFolderOpen, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMutation, useQuery } from '@tanstack/react-query';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFeedback from '../../hooks/useFeedback';
import useModal from '../../hooks/useModal';
import useSession from '../../hooks/useSession';
import getCompetitions from '../../services/getCompetitions';
import getDomain from '../../services/getDomain';
import getFinishedCompetitionDetails from '../../services/getFinishedCompetitionDetails';
import accessCamera from '../../utils/accessCamera';
import formatDate from '../../utils/formatDate';
import formatTime from '../../utils/formatTime';
import repeat from '../../utils/repeat';
import AccessCamera from '../modals/AccessCamera';
import FinishedCompetitionDetailsModal from '../modals/FinishedCompetitionDetailsModal';
import SkeletonModal from '../modals/SkeletonModal';
import StartCompetition from '../modals/StartCompetition';
import Card from '../molecules/Card';
import Pager from '../molecules/Pager';

const color = { 'not-started': 'blue', 'in-progress': 'green', finished: 'red' };

/**
 * Competitions organism creates a search engine and viewer for public/private/history competitions
 * @param {{
 *      visibility: 'public' | 'private' | 'history';
 *      criteria: {
 *          name: string;
 *          status: ('not-started' | 'in-progress' | 'finished')[];
 *          categories: number[];
 *      }
 * }} props
 */
function Competitions({ visibility, criteria }) {
    const { session } = useSession();
    const { feedbackElement, createFeedback, removeFeedback } = useFeedback();
    const { createModal, removeModal } = useModal();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [competitionDetails, setCompetitionDetails] = useState(null);
    const [loadingDetails, setLoadingDetails] = useState(false);

    const competitionsQuery = useQuery({
        queryKey: ['competitions', visibility, page, criteria, session],
        queryFn: () => getCompetitions(visibility, page, criteria, session, i18next.language),
        enabled: visibility === 'public' || !!session,
        refetchOnMount: true,
    });

    const domainMutation = useMutation({
        mutationKey: ['competitions', 'rules'],
        mutationFn: (competition) => getDomain(competition, session, i18next.language),
    });

    const loadCompetitionDetails = async (guid) => {
        try {
            setLoadingDetails(true);
            const details = await getFinishedCompetitionDetails(guid, session, i18next.language);
            setCompetitionDetails(details);
        } finally {
            setLoadingDetails(false);
        }
    };

    useEffect(() => {
        if (competitionDetails && !loadingDetails) {
            const onCancel = () => {
                removeModal();
                setCompetitionDetails(null);
            };

            createModal(
                <FinishedCompetitionDetailsModal
                    competitionTitle={competitionDetails.competition.competition}
                    competitionDate={competitionDetails.competition.candidate.date}
                    competitionScore={competitionDetails.competition.score}
                    competitionCategories={competitionDetails.competition.categories}
                    onCancel={onCancel}
                />,
            );
        }
    }, [competitionDetails, loadingDetails, createModal, removeModal]);

    // Reset page to 0 when visibility changes
    useEffect(() => {
        setPage(0);
    }, [visibility]);

    const handleCompetition = async (guid, status, timeRemaining, startDate, endDate, duration) => {
        if (!session) {
            createFeedback('message', i18next.t('login-to-start'), true);
            navigate('/auth/sign-in');
            return;
        }

        if (startDate && new Date() < new Date(startDate)) {
            createFeedback('warn', i18next.t('competition-not-started-yet'));
            return;
        }

        if (status === 'finished' && duration !== null) {
            createFeedback('warn', i18next.t('competition-is-over'));
            return;
        }

        if (status === 'finished' && duration == null && !competitionDetails && !loadingDetails) {
            removeFeedback();
            createModal(<SkeletonModal />, true);
            loadCompetitionDetails(guid);
            return;
        }

        const domainRules = await domainMutation.mutateAsync(guid);

        if (domainRules.setting.includes('FORCECAM')) {
            try {
                await accessCamera();
            } catch (_) {
                createModal(<AccessCamera onCancel={() => removeModal()} />);
                return;
            }
        }

        if (status !== 'in-progress' && timeRemaining !== null) {
            const onAccept = () => {
                removeModal();
                navigate(`/competitions/${visibility}/${guid}`);
            };
            const onCancel = () => {
                removeModal();
            };

            createModal(<StartCompetition time={timeRemaining} onAccept={onAccept} onCancel={onCancel} />, true);
            return;
        }

        navigate(`/competitions/${visibility}/${guid}`);
    };

    return (
        <div className="w-full flex flex-col gap-5">
            {feedbackElement}
            {competitionsQuery.isLoading ? (
                <div className="w-full h-min grid lg:grid-cols-2 grid-cols-1 gap-5">
                    {repeat(
                        parseInt(process.env.REACT_APP_COMPETITIONS_PER_PAGE, 10),
                        <Card isLoading title="" contents={[]} />,
                    )}
                </div>
            ) : !competitionsQuery.data ||
              !competitionsQuery.data.competitions ||
              competitionsQuery.data.competitions.length === 0 ? (
                <div className="w-full h-[70vh] flex flex-col gap-5 justify-center items-center">
                    <FontAwesomeIcon icon={faFolderOpen} size="5x" className="text-smgreen-dark" />
                    <p className="sm:text-2xl text-lg text-center">{i18next.t('no-competitions-found')}</p>
                </div>
            ) : (
                <div className="w-full h-min grid lg:grid-cols-2 grid-cols-1 gap-5">
                    {competitionsQuery.data.competitions.map((competition) => {
                        const contents = [];
                        const {
                            guid,
                            name,
                            status,
                            challenges,
                            categories,
                            duration,
                            timeRemaining,
                            startDate,
                            endDate,
                        } = competition;

                        if (status === 'in-progress') {
                            contents.push({
                                alt: i18next.t('time-remaining'),
                                icon: faHourglassHalf,
                                text: timeRemaining ? formatTime(timeRemaining, 2) : i18next.t('unlimited'),
                            });
                        } else {
                            contents.push({
                                alt: i18next.t('duration'),
                                icon: faClock,
                                text: duration ? formatTime(duration, 2) : i18next.t('unlimited'),
                            });
                        }

                        if (startDate) {
                            contents.push({
                                alt: i18next.t('start-date'),
                                icon: faCalendar,
                                text: formatDate(startDate),
                            });
                        }

                        if (endDate) {
                            contents.push({
                                alt: i18next.t('end-date'),
                                icon: faCalendarXmark,
                                text: formatDate(endDate),
                            });
                        }

                        // Competition is public based on duration (toChange)
                        const isPublic = duration === null;

                        // Show the visual label only for finished competitions in the history
                        const showVisualLabel = status === 'finished' && visibility === 'history';

                        return (
                            <Card
                                key={`competition:${guid}`}
                                title={name}
                                tag={status && i18next.t(status)}
                                color={color[status]}
                                contents={contents}
                                tags={categories}
                                detail={`${challenges} ${i18next.t('challenges')}`}
                                onClick={() =>
                                    handleCompetition(guid, status, timeRemaining, startDate, endDate, duration)
                                }
                                isPublic={showVisualLabel ? isPublic : undefined}
                            />
                        );
                    })}
                </div>
            )}

            {competitionsQuery.data && competitionsQuery.data.competitions.length !== 0 && (
                <div className="w-full flex justify-center">
                    <Pager
                        isLoading={competitionsQuery.isLoading}
                        pages={competitionsQuery.data.pages || 5}
                        page={page}
                        setPage={setPage}
                    />
                </div>
            )}
        </div>
    );
}

Competitions.propTypes = {
    visibility: PropTypes.oneOf(['public', 'private', 'history']).isRequired,
    criteria: PropTypes.shape({
        name: PropTypes.string.isRequired,
        status: PropTypes.arrayOf(PropTypes.oneOf(['not-started', 'in-progress', 'finished'])).isRequired,
        categories: PropTypes.arrayOf(PropTypes.number).isRequired,
    }).isRequired,
};

export default Competitions;
