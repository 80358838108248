import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Fragment, useRef, useState, useEffect   } from 'react';
import Skeleton from 'react-loading-skeleton';
import useDisplay from '../../hooks/useDisplay';

/**
 * Select atom creates an option selector with a loading state
 * @param {{
 *      isLoading: boolean;
 *      placeholder: string;
 *      selected: string;
 *      onSelect: (selected: string) => void;
 *      options: { [section: string]: { [key: string]: string; } };
 *      order: string[];
 *      color?: 'gray' | 'white';
 *      enableSearch?: boolean;
 * }} props
 */
function Select({ isLoading, placeholder, options, selected, onSelect, order, color = 'white', enableSearch = false }) {
    const { targetRef, display, setDisplay } = useDisplay({ displayed: false });
    const [searchTerm, setSearchTerm] = useState('');

    const inputRef = useRef(null);

    useEffect(() => {
        if (display && inputRef.current) {
            inputRef.current.focus();
        }
    }, [display]);

    /**
     * Set a selection
     * @param {string} key
     */
    const handleSelect = (key) => {
        setDisplay(false);
        onSelect(key);
    };

    const filteredOptions = (section) => {
        const optionKeys = order || Object.keys(options[section]).sort((a, b) =>
            options[section][a].localeCompare(options[section][b])
        );

        if (!enableSearch || searchTerm.trim() === '') {
            return optionKeys;
        }

        return optionKeys.filter((key) =>
            options[section][key].toLowerCase().includes(searchTerm.toLowerCase())
        );
    };

    if (isLoading) {
        return (
            <Skeleton
                baseColor="#e4e4e7"
                highlightColor="#f4f4f5"
                height="100%"
                borderRadius={6}
                containerClassName="w-full flex h-10"
            />
        );
    }

    return (
        <div ref={targetRef} className="relative select-none">
            <button
                type="button"
                onClick={() => setDisplay((prevDisplay) => !prevDisplay)}
                // onClick={() => setDisplay(true)}
                className={classNames(
                    "w-full px-5 h-10 flex justify-between items-center rounded-md shadow-md outline-none",
                    color === 'gray' ? 'bg-smgray shadow-inner' : 'bg-white shadow-md',
                )}
            >
                <p className="text-sm">{Object.assign({}, ...Object.values(options))[selected] || placeholder}</p>
                <FontAwesomeIcon icon={display ? faCaretUp : faCaretDown} />
            </button>

            {display && (
                <div className="absolute top-full z-30 w-full max-h-64 mt-2 flex flex-col bg-white rounded-md shadow-md divide-y overflow-x-hidden overflow-y-auto">
                    {enableSearch && (
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder="Search..."
                            className="px-5 py-2 outline-none"
                            ref={inputRef}
                        />
                    )}

                    {Object.keys(options).map((section) => (
                        <Fragment key={`select:section:${section}`}>
                            {section !== 'null' && <p className="text-sm font-bold px-5 py-3">{section}</p>}
                            {filteredOptions(section).map((key) => (
                                <button
                                    key={`select:option:${key}`}
                                    type="button"
                                    onClick={() => handleSelect(key)}
                                    className={classNames(
                                        'w-full py-2 flex items-center outline-none',
                                        section !== 'null' ? 'px-10' : 'px-5',
                                        { 'bg-smgreen-dark text-white': selected === key },
                                    )}
                                >
                                    <p className="text-sm">{options[section][key]}</p>
                                </button>
                            ))}
                        </Fragment>
                    ))}
                </div>
            )}
        </div>
    );
}

Select.propTypes = {
    isLoading: PropTypes.bool,
    placeholder: PropTypes.string,
    options: PropTypes.shape({
        [PropTypes.string]: PropTypes.shape({
            [PropTypes.string]: PropTypes.string,
        }),
    }).isRequired,
    selected: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    order: PropTypes.arrayOf(PropTypes.string),
    color: PropTypes.oneOf(['gray', 'white']),
    enableSearch: PropTypes.bool,
};

Select.defaultProps = {
    isLoading: false,
    placeholder: '',
    order: null,
    selected: null,
    color: 'white',
    enableSearch: false,
};

export default Select;
