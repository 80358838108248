import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import useSession from '../../hooks/useSession';

function ProtectedRoute ({ children })  {
    const { session } = useSession();

    if (!session) {
        return <Navigate to="/auth/sign-in" />;
    }

    return children;
};
ProtectedRoute.propTypes = {
    children: PropTypes.node.isRequired,
};

export default ProtectedRoute;