import { useCallback, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import Container from '../components/atoms/Container';

/**
 * useModal hook creates a modal outside of root
 * @returns {{
 *      createModal: (content: React.ReactNode) => void;
 *      removeModal: () => void;
 * }}
 */
function useModal() {
    const modalRef = useRef();
    const targetRef = useRef();
    const shouldCloseOnOutsideClick = useRef(true);

    /**
     * Removes the displayed modal
     */
    const removeModal = () => {
        modalRef.current?.unmount();
        modalRef.current = undefined;
    };

    /**
     * Create a dynamic content modal
     * @param {React.ReactNode} content
     * @param {boolean} enableVerticalScroll - Optional parameter to enable vertical scroll
     * @param {boolean} wider - Optional parameter to make modal wider
     * @param {boolean} closeOnOutsideClick - Optional parameter to enable/disable closing on outside click
     */
    const createModal = (content, enableVerticalScroll = false, wider = false, closeOnOutsideClick = true) => {
        shouldCloseOnOutsideClick.current = closeOnOutsideClick;

        const modalRoot = modalRef.current || createRoot(document.getElementById('modal'));
        modalRoot.render(
            <div className="fixed z-40 top-0 left-0 w-full h-screen px-5 flex items-center justify-center bg-black bg-opacity-75 select-none">
                <div ref={targetRef} className={`${wider ? 'xl:w-2/3' : 'xl:w-1/3'} ${wider ? 'md:w-3/4' : 'md:w-1/2'} w-full max-h-full ${enableVerticalScroll ? 'overflow-y-auto' : 'overflow-y-hidden'}`}>
                    <Container>{content}</Container>
                </div>
            </div>,
        );

        modalRef.current = modalRoot;
    };
    
    /**
     * Hide the target when clicked outside of it
     * @param {MouseEvent} event
     */
    const handleClickOutside = useCallback((event) => {
        if (shouldCloseOnOutsideClick.current && targetRef.current && !targetRef.current.contains(event.target)) {
            removeModal();
        }
    }, []);

    /**
     * Hide the target when the ESC key is pressed
     * @param {KeyboardEvent} event
     */
    const handleKeyPress = useCallback((event) => {
        if (event.key === 'Escape') {
            removeModal();
        }
    }, []);

    // Add event listeners
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keyup', handleKeyPress);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keyup', handleKeyPress);
        };
    }, [handleClickOutside, handleKeyPress]);

    return { createModal, removeModal };
}

export default useModal;
