import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { useState } from 'react';
import Container from '../atoms/Container';

/**
 * CardToggle es un contenedor toggle que puede ser
 * minimizado/maximizado haciendo clic en él.
 * @param {{
 *      title: string;
 *      border: 'green' | 'blue' | 'orange' | 'red';
 *      size: 'xl' | 'lg' | 'md' | 'sm';
 *      children: React.ReactNode;
 * }} props
 */
function CardToggle({ title, size, border, children }) {
    const [isMinimized, setIsMinimized] = useState(false);

    const handleToggleClick = () => {
        setIsMinimized((prev) => !prev);
    };

    return (
        <Container border={border}>
            {!isMinimized ? (
                <button
                    type="button"
                    className={`flex w-full justify-between items-center cursor-pointer font-bold p-6 border-b text-${size}`}
                    onClick={handleToggleClick}
                    style={{ cursor: 'pointer' }}
                >
                    {title}
                    <FontAwesomeIcon icon={faAngleDown} className="ml-2 flex justify-center items-center" />
                </button>
            ) : (
                <>
                    <button
                        type="button"
                        className={`flex w-full justify-between items-center text-${size} font-bold p-6 border-b`}
                        onClick={handleToggleClick}
                        style={{ cursor: 'pointer' }}
                    >
                        {title}
                        <FontAwesomeIcon icon={faAngleUp} className="ml-2 flex justify-center items-center" />
                    </button>
                    {children}
                </>
            )}
        </Container>
    );
}

CardToggle.propTypes = {
    title: PropTypes.string,
    border: PropTypes.oneOf(['green', 'blue', 'orange', 'red']),
    size: PropTypes.oneOf(['xl', 'lg', 'md', 'sm']),
    children: PropTypes.node.isRequired,
};

CardToggle.defaultProps = {
    title: '',
    border: undefined,
    size: 'xl',
};

export default CardToggle;
